module.exports = {
  main: {
    site_id: 433,
    skin: 'time4bets-esport',
    title: 'Time4bets-Esports',
    mathCuttingFunctionForExpOdds: true,
    enablePlayerNationality: true,
    disableMultipleSingleBets: true,
    changeTimeFiltersTimeZone: '-04:00',
    changeGamesStartTimeByTimeZone: 14400,
    fractionalBaseFormat: true,
    homeAwayBaseRecalculationEnabled: true,
    decimalFormatRemove3Digit: true,
    teamLogosPath: 'https://statistics.betcoapps.com/images',
    removeDotFromVirtualKeyBoard: true,
    hasBetSlipEventsLimit: 15,
    sportsbookViews: ['classic_esport', 'asian_esport'],
    shortDateTimeFormat: 'MM/DD HH:mm',
    shortDateFormat: 'MM.DD',
    setDefaultBetSlipSettingType: true,
    clearSelectionsAfterLogOut: true,
    disableLiveGamesInToday: true,
    enableHideBalance: true,
    traditionalSportsBookUrl: 'https://m.time4bets.com',
    availableOddFormats: ['decimal', 'hongkong', 'malay', 'indo'],
    blockedPrices: {
      minPrice: 0.01,
      maxPrice: 1,
    },
    roundDecimalCoefficients: 2,
    specialOddFormat: {},
    overrideErrorMessages: {
      '2200': 'api_518',
      '2421': 'api_515',
    },
    indoAndMalayToHongkong: true,
    enableProfitBoost: true,
    quickBet: {
      hideQuickBet: true,
    },
    enableOddsFormatInBetslip: true,
    enableBetHistoryInFooter: true,
    asianInitialTimeFilters: [0, 1, 2, 3, 4, 5, 6],
    balance: {
      disableDepositButton: true,
      disableMenu: true,
    },
    availableLanguages: {
      '@replace': true,
      eng: {
        short: 'EN',
        full: 'English',
        order: 1,
      },
      arb: {
        short: 'AR',
        full: 'Arabic',
        order: 2,
      },
      fre: {
        short: 'FR',
        full: 'Français',
        order: 3,
      },
      fas: {
        short: 'FA',
        full: 'فارسی',
        order: 4,
        rtl: true,
      },
      tur: {
        short: 'TR',
        full: 'Türkçe',
        order: 5,
      },
      kur: {
        short: 'KU',
        full: 'Kurdish',
        order: 6,
      },
    },
    mainMenuItemsOrder: [
      {
        title: 'asian-prematch',
        order: 1,
      },
      {
        title: 'asian-live',
        order: 2,
      },
      {
        title: 'asian-esport-prematch',
        order: 3,
      },
      {
        title: 'asian-esport-live',
        order: 4,
      },
      {
        title: 'european-esport-prematch',
        order: 5,
      },
      {
        title: 'european-esport-live',
        order: 6,
      },
      {
        title: 'game-results',
        order: 7,
      },
    ],
    video: {
      enableForLogoutUser: true,
      evenIfNotLoggedIn: true,
    },
    mainMenuItems: null,
    rightMenuContentLinks: [],
    showLiveIndicator: 'all',
    regConfig: {
      fields: {
        agree: false,
        re_captcha: {
          order: 24,
          title: 'Re Captcha',
          skippAbleForProfileValidation: true,
          name: 'g_recaptcha_response',
          placeholder: 'Enter here',
          type: 're_captcha',
          classes: 'single-form-item',
          customAttrs: {
            required: true,
          },
          validationMessages: {
            required: 'This field is required',
          },
        },
      },
    },
    payments: [],
  },
  env: {
    lang: 'eng',
  },
  isPartnerIntegration: {
    mode: {
      iframe: true,
    },
    iframe: {
      disableHeader: true,
      disableNavigationMenu: false,
      disableLeftMenu: true,
      disableRightMenu: false,
      disablePopups: false,
      disableFooter: true,
    },
    discardGetBalance: true,
  },
  betting: {
    enableExpressBonus: false,
    enablePartialCashOut: false,
    enableSuperBet: false,
    totalOddsMax: 10000,
    enableOutright: true,
    enableCounterOffer: true,
  },
  cms: {
    baseHost: 'www.time4bets.com',
    configFromCmsLink:
      'https://cmsbetconstruct.com/skins/time4bets.cms.betconstruct.com/js/conf.json',
  },
  casino: {
    urlPrefix: false,
  },
  swarm: {
    url: [
      {
        url: 'https://eu-swarm-lp.betcoswarm.com/',
      },
    ],
    websocket: [
      {
        url: 'wss://eu-swarm-ws-re.betcoswarm.com/',
      },
    ],
  },
  theming: {
    color1: {
      title: 'default',
      options: {
        heroColor: '#207f60',
        skinColor: '#464646',
        textColor: '#464646',
        header: '#207f60',
        favoriteColor: '#ffdc41',
        oddColor: '#ebd402',
        brand: '#2b9b73',
      },
      order: 1,
    },
  },
};
