module.exports = {
  main: {
    source: 4,
    terminalId: 1,

    site_id: 1, // partner ID
    slug: 1,
    trackingLogs: false,
    textUnderMarkets: true,
    preloadPromotions: true,
    showEuropeanPopup: false,
    loadConfigsFromCms: false,
    enableRuntimePopup: true,
    disableWithVideoTab: false,
    enableLeftMenuSecondStyle: false,
    googleAnalyticsId: '',
    esportSkinPrefix: '',
    showItfStatistics: true,
    GmsPlatform: true,
    useBrowserHistory: true, //use browser history instead of hash history (in IE8, IE9 and older browsers page will be reloaded on navigation)
    authSessionLifeTime: 2628000, // time to store login (in seconds)
    authSessionLessLifeTime: 1800, // time to store login (in seconds)
    disableFreeBet: false,
    hasBetSlipEventsLimit: 16,
    legacyActions: ['register', 'login', 'reset_password', 'verify'],
    keepPopupsAfterLocationChange: false,
    withThousandSeparator: false,
    enableTabletVersion: true,
    commaSeparator: false,
    reCaptchaVersion: 3, // If this config is set 3, please check response of getSession call, there also should be version 3. In other case change it to 2.
    euroPaymentIdes: [87], // time to store login (in seconds)
    betslip: {
      showEventsCount: false,
    },
    balance: {
      goToDepositWhenBalanceIsLow: false,
    },
    marketGroup: {
      showOpenBets: false,
    },
    betslipAdditionalMessages: {
      insufficientBalance: {
        link: '/balance/deposit',
        linkTarget: '_self',
        message: 'Insufficient balance',
        postFix: 'Deposit',
      },
    },
    europeanPopupConfig: {
      subscribe_to_internal_message: {
        title: 'Internal Messages',
        key: 'subscribe_to_internal_message',
      },
      subscribe_to_push_notification: {
        title: 'Notifications',
        key: 'subscribe_to_push_notification',
      },
      subscribe_to_phone_call: {
        title: 'Phone',
        key: 'subscribe_to_phone_call',
      },
      subscribe_to_email: { title: 'Email', key: 'subscribe_to_email' },
      subscribe_to_sms: { title: 'SMS', key: 'subscribe_to_sms' },
    },
    popularGameId: [1, 3, 4],
    skillGamesFullList: [
      {
        name: 'poker',
        itemName: 'poker',
        id: 28,
      },
      {
        name: 'ogwil',
        itemName: 'ogwil',
        id: 116,
      },
      {
        name: 'chingaChoong',
        itemName: 'chingaChoong',
        id: 29,
      },
      {
        name: 'backgammon',
        itemName: 'backgammon',
        id: 11,
      },
      {
        name: 'backgammon',
        itemName: 'backgammon(fromUrl)',
        id: 11,
      },
      {
        name: 'belote',
        itemName: 'belote',
        id: 10,
      },
      {
        name: 'belote',
        itemName: 'belote(fromUrl)',
        id: 10,
      },
    ],
    disableGoalScores: false,
    availableLanguages: {
      eng: {
        short: 'EN',
        full: 'English',
        order: 1,
      },
      spa: {
        short: 'ES',
        full: 'Español',
        order: 2,
      },
      arm: {
        short: 'HY',
        full: 'Հայերեն',
        order: 4,
      },
      rus: {
        short: 'RU',
        full: 'Русский',
        order: 3,
      },
      por: {
        short: 'PT',
        full: 'Português',
        order: 5,
      },
      tur: {
        short: 'TR',
        full: 'Türkçe',
        order: 6,
      },
      kor: {
        short: 'KO',
        full: '한국어',
        order: 7,
      },
      jpn: {
        short: 'JP',
        full: '日本語',
        order: 8,
      },
      chi: {
        short: 'CH',
        full: '繁体中文',
        order: 9,
      },
      geo: {
        short: 'KA',
        full: 'ქართული',
        order: 10,
      },
      swe: {
        short: 'SE',
        full: 'Swedish',
        order: 11,
      },
      ger: {
        short: 'DE',
        full: 'Deutsch',
        order: 12,
      },
      nor: {
        short: 'NO',
        full: 'Norwegian',
        order: 13,
      },
    },
    mapLanguages: {
      uae: 'AE',
      heb: 'HE',
    },
    betRadarAnimation: false,
    disableVirtualSports: false,
    enableLoyaltyPoints: false,
    enableHideBalance: false, // in order in enable "hide balance" in "header" container the "header-separator-m" className should be changed,in "balance" component "balance-user-view-m" className should be changed and <b/> added, check global files
    customTags: [], //generate additional tags for skins use this format [{tag: "tagName", [prop1: 'prop1', prop2: 'prop2'...]}],
    loyaltyPointsShowAlwaysNextLevel: false,
    baseLinkForFantasy: 'https://m.fantasybet.co', // TODO check url
    enableFantasySports: false,
    asianInitialTimeFilters: [1],
    languageSelectorMode: 'select', // "select" or "list"
    defaultCurrencyRounding: 2, //number of decimal places to round displayed amounts (used if cannot be found in config loaded from swarm)
    availableOddFormats: ['decimal', 'fractional', 'american', 'hongkong', 'malay', 'indo'],
    useLadderForFractionalFormat: true, // use "ladder" when displaying odds in fractional format
    roundDecimalCoefficients: 3, // rounding of coefficients, number of significant digits
    showPointsInsteadCurrencyName: false, //replace all currency names with "PTS"
    mainMenuItemsOrder: [
      {
        title: 'prematch',
        order: 1,
      },
      {
        title: 'live',
        order: 2,
      },
      {
        title: 'casino',
        order: 3,
      },
      {
        title: 'live-casino',
        order: 4,
      },
    ],
    mainMenuItems: {
      belote: {
        title: 'Belote',
        target: '_blank',
        needToLogin: true,
        id: 10,
      },
      backgammon: {
        title: 'backgammon',
        target: '_blank',
        needToLogin: true,
        id: 11,
      },
      poker: {
        title: 'Poker',
        target: '_blank',
        needToLogin: true,
        id: 28,
      },
      ogwil: {
        title: 'Ogwil',
        target: '_blank',
        needToLogin: true,
        id: 116,
      },
      colossusBets: {
        title: 'COLOSSUSBETS',
        target: '_self',
        needToLogin: true,
        id: 152000,
      },
      chingaChoong: {
        title: 'CHINGACHOONG',
        target: '_blank',
        needToLogin: true,
        id: 29,
      },
      betGame: {
        title: 'Betgames TV',
        target: '_blank',
        needToLogin: true,
        id: 9002,
      },
      finbet: {
        title: 'finbet',
        target: '_blank',
        needToLogin: true,
        id: 15,
      },
      tradologic: {
        title: 'tradologic',
        target: '_blank',
        needToLogin: true,
        id: 14000,
      },
    },
    rightMenuContentLinks: [
      {
        link: 'contact-support',
        title: 'Contact Support',
        type: 'support',
      },
    ],
    enableBalanceTransactions: false,
    rightMenuVisibleCMSContentLinksSlugs: [
      'general-terms-and-conditions',
      'privacy-policy',
      'cookie-policy',
      'affiliate-program',
      'about-us',
      'contact-us',
      'responsible-gaming',
      'help',
    ], //Temporary solution until specific field will be added into cms
    showGameStatistics: false,
    statsHostname: {
      prefixUrl: 'http://statistics.betconstruct.com/#/',
      defaultLang: 'en',
      subUrl: '/external/page/',
    },
    personalDetails: {
      readOnlyFields: [
        'id',
        'username',
        'first_name',
        'last_name',
        'birth_date',
        'gender',
        'email',
        'doc_number',
      ],
      editableFields: ['country_code', 'city', 'phone', 'bank_info', 'password', 'address'],
      requiredFields: ['country_code', 'city', 'phone', 'password'],
      bankInfoMask: '99999999999',
    },
    video: {
      enableLiveGamesFilter: true,
      //availableVideoProviderIds: [1, 3, 5, 6, 16, 19, 25], //list of available providers
      enabled: true,
      autoPlay: true,
      useHlsUrlFromResponse: false, //only for topSport skin
      evenIfNotLoggedIn: false,
    },
    bonus: {
      disableBonusCanceling: false,
    },
    androidAppSettings: {
      showAndroidAppDownloadPopup: false,
      showCasinoAppDownloadPopup: false,
      downloadLink: '',
      downloadLinkCasino: '',
    },
    gamesAuthorizationUrl: '/authorization.php',
    esportsGames: [
      'Dota',
      'Dota2',
      'CounterStrike',
      'LeagueOfLegends',
      'StarCraft',
      'StarCraft2',
      'Hearthstone',
      'CallofDuty',
      'Overwatch',
      'HeroesOfTheStorm',
    ],
    teamLogosPath: 'https://statistics.bcapps.org/images/',
    geoipLink: 'https://geoapi.bcapps.org/',
    minimalPasswordLength: 6,
    userDepositLimits: {
      enabled: false,
    },
    userBetLimits: {
      enabled: false,
    },
    selfExclusionByExcType: 2, // config values are   false, "1", "2"
    userSelfExclusion: {
      // user selfExclusion settings
      enabled: true,
      options: [
        {
          name: '6-month',
          limit: {
            value: '6-month',
          },
        },
        {
          name: '1-year',
          limit: {
            value: '1-year',
          },
        },
        {
          name: '2-year',
          limit: {
            value: '2-year',
          },
        },
        {
          name: '5-year',
          limit: {
            value: '5-year',
          },
        },
      ],
    },
    realityChecks: {
      enabled: false,
      options: [
        {
          name: 'no limit',
          value: '0',
        },
        {
          name: '10 mins',
          value: '600',
        },
        {
          name: '20 mins',
          value: '1200',
        },
        {
          name: '30 mins',
          value: '1800',
        },
        {
          name: '1 hour',
          value: '3600',
        },
        {
          name: '2 hour',
          value: '7200',
        },
        {
          name: '4 hour',
          value: '14400',
        },
        {
          name: '6 hour',
          value: '21600',
        },
        {
          name: '8 hour',
          value: '28800',
        },
      ],
    },
    documentUploadAdditionalInfo: {
      'Bank Slip': 1,
      Document: 2,
      Passport: 3,
      'Driving License': 4,
      IBAN: 5,
      'Social Card ID': 6,
      Other: 7,
    },
    dateFormat: 'ddd, D MMM YYYY', // momentJS display format string
    asianDateFormat: 'ddd D', // momentJS display format string
    timeFormat: 'HH:mm', // momentJS display format string
    dateTimeFormat: 'ddd, D MMM YYYY, HH:mm', // momentJS display format string
    shortDateTimeFormat: 'ddd HH:mm', // momentJS display format string
    shortDottedDateTimeFormat: 'MM.DD | HH:mm', // momentJS display format string
    fullDottedDateFormat: 'HH:mm | DD.MM.YYYY', // momentJS display format string
    fullDateTimeFormat: 'YYYY-MM-DD[T]HH:mm:ss', // momentJS display format string
    prematchTimeFilterValues: [3, 6, 12, 24, 48, 72], // hours (above sports list in prematch)
    prematchWidgetTimeFilterValues: [5, 10, 15, 30], // mins (dashboard)
    regConfig: {
      settings: {
        minYearOld: 18,
        maxYearOld: 100,
        minYearOldForPayments: 0,
        defaultCountry: null, //iso standard
        loginAfterRegistration: true,
        sendEmailAsUsername: false,
        dateFormat: 'YYYY-MM-DD',
        restrictedCountries: [], //["AU"] iso standard
        disablePhoneValidation: false,
      },
    },
    balanceHistoryMainSelectableTypes: [
      '0',
      '1',
      '3',
      '9',
      '10',
      '12',
      '14',
      '15',
      '16',
      '50',
      '83',
      '84',
      '301',
      '302',
    ],
    balanceHistoryCasinoSelectableTypes: [0, 1, 2, 3, 4, 5, 6],

    payments: [],
    paymentByCurrency: false,
    buttonsDefaultValues: {
      AMD: {
        deposit: [5000, 10000, 15000, 50000, 100000, 150000],
        withdraw: [5000, 10000, 15000, 50000, 100000, 150000],
      },
      USD: {
        deposit: [20, 50, 100, 200, 500, 1000],
        withdraw: [20, 50, 100, 200, 500, 1000],
      },
      EUR: {
        deposit: [20, 50, 100, 200, 500, 1000],
        withdraw: [20, 50, 100, 200, 500, 1000],
      },
      PLN: {
        deposit: [50, 100, 200, 500, 1000, 2000],
        withdraw: [50, 100, 200, 500, 1000, 2000],
      },
      SEK: {
        deposit: [100, 300, 500, 1000, 3000, 5000],
        withdraw: [100, 300, 500, 1000, 3000, 5000],
      },
      RUB: {
        deposit: [1000, 2000, 5000, 10000, 20000, 50000],
        withdraw: [1000, 2000, 5000, 10000, 20000, 50000],
      },
      UAH: {
        deposit: [500, 1000, 2000, 5000, 10000, 20000],
        withdraw: [500, 1000, 2000, 5000, 10000, 20000],
      },
      CNY: {
        deposit: [100, 300, 500, 1000, 3000, 5000],
        withdraw: [100, 300, 500, 1000, 3000, 5000],
      },
      GBP: {
        deposit: [20, 50, 100, 200, 500, 1000],
        withdraw: [20, 50, 100, 200, 500, 1000],
      },
      KZT: {
        deposit: [5000, 15000, 30000, 50000, 150000, 300000],
        withdraw: [5000, 15000, 30000, 50000, 150000, 300000],
      },
      KRW: {
        deposit: [10000, 50000, 100000, 500000],
        withdraw: [10000, 50000, 100000, 500000],
      },
      MXN: {
        deposit: [500, 1000, 2000, 5000, 10000, 20000],
        withdraw: [500, 1000, 2000, 5000, 10000, 20000],
      },
    },
    marketsCollapsedByDefault: true,
  },
  build: {
    publicPath: '/',
  },
  betting: {
    disableAutoPartialCashout: false,
    disablePartialCashout: false,
    bet_source: '4',
    maxWinLimit: false,
    enableAcceptChangesAndPlaceBets: false,
    disableMaxBet: false,
    enableExpressBonus: false,
    enableSuperBet: false,
    showSuperBetNotificationsViaPopup: false,
    enableHorseRacingBetSlip: true, // SP, new bet types, etc.
    enableEachWayBetting: false,
    enableShowBetSettings: false,
    allowManualSuperBet: false,
    // superBetCheckingIntervalTime: 5000,
    showFinalPayout: false,
    betAcceptedMessageTime: 5000,
    quickBetNotificationsTimeout: 4000,
    autoSuperBetLimit: {}, // {'GEL': 400, 'AMD': 50000, 'USD': 1000} //if not false then set limit for each currency if stake is greater then Limit superbet is enabling automaticaly
    resetAmountAfterBet: false,
    totalOddsMax: 1000,
    enableLimitExceededNotifications: false,
    allowSuperBetOnLive: false,
    enableBetterOddSelectingFunctyionality: false,
    rememberAutoAcceptPriceChangeSettings: false,
    enableBankerBet: false,
    enableBookingBet: false,
    quickBet: {
      hideQuickBet: false,
      enableQuickBetStakes: false,
      visibleForPaths: ['sport', 'overview'],
      quickBetStakeCoeffs: {
        USD: 5,
        AMD: 50,
        EUR: 3,
      },
      quickBetStakeBases: [1, 2, 5, 10],
      quickBetBaseMultipliers: [1, 10, 100],
    },
    disableClearAllInBetProgress: false,
    disableMaxButtonInBetProgress: false,
    allowBetWithoutLogin: true, // allow to click "place bet" without logging in. will get corresponding error message from backend and open login form
    enablePartialCashOut: false,
    disableBetslipTypes: [],
  },
  partner: {},
  rfid: {},
  swarm: {
    debugging: false, //enable websocket debugging
    languageMap: {
      pol: 'eng',
      por: 'por_2',
      'pt-br': 'por',
      fre: 'fra',
      chi: 'zho',
      mac: 'mkd',
      bgr: 'bul',
      lat: 'lav',
      fas: 'fas',
      rum: 'ron',
    },
    sendSourceInRequestSession: true,
    sendTerminalIdlInRequestSession: false,
    webSocketTimeout: 5000,
    //url: [{url: "https://192.168.250.189:8022"}],
    url: [
      {
        url: 'https://eu-swarm-lp.betconstruct.com/',
      },
    ],
    //websocket: [{url: "wss://192.168.250.189:8022"}],
    websocket: [
      {
        url: 'wss://eu-swarm-ws.betconstruct.com/',
      },
    ],
    // websocket: [{url: "ws://127.0.0.1:8080"}],
    useWebSocket: true,
    maxWebsocketRetries: 5, // maximum number of websocket reconnect attempts after which it will give up
    webSocketRetryInterval: 2000, // retry interval, in milliseconds (will be increased after each unsuccessful retry by itself)
  },
  cms: {
    url: 'https://cmsbetconstruct.com/',
    baseHost: 'vbet',
    mostPopularGame: {
      json: 'widgets/get_sidebar',
      sidebarId: 'banner-most-popular-game-app-',
    },
  },
  casino: {
    providersThatWorkWithSwarm: [],
    // providersThatWorkWithCasinoBackend: {
    //     providers: ['GDR'],
    //     url: "https://launchgames.vivaro.am/global/play.php"
    // },
    urlPrefix: false,
    gamesUrl: '/global/mplay1.php',
    url: 'https://www.cmsbetconstruct.com/casino/',
    disableCasinoPlayForFunMode: false,
    enableBaseAuthorization: true,
    gamesAuthorizationUrl: '/authorization.php',
    allProviders: {
      showAll: true,
    },
    allCategories: {
      showAll: true,
    },
    games: {
      loaderStep: 16,
    },
  },
  tournaments: {
    firstLoadStep: 10,
    loaderStep: 2,
    filtersData: [
      {
        name: 'Statuses',
        field: 'state',
        filters: [
          { name: 'Upcoming', value: 3 },
          { name: 'Live', value: 2, active: true },
          { name: 'Finished', value: 1 },
          { name: 'Canceled', value: -1 },
        ],
      },
      {
        name: 'Registration statuses',
        field: 'registrationStatus',
        filters: [
          { name: 'Registration Started', value: 1, active: true },
          { name: 'Registration Finished', value: 0 },
        ],
      },
      {
        name: 'Entry types',
        field: 'buyInStatus',
        filters: [
          { name: 'Buy-in', value: 2 },
          { name: 'Free Entry', value: 1, active: true },
        ],
      },
    ],
  },
  liveCasino: {
    helpUrl: 'https://websitelivegames-am.betconstruct.com/Home/HelpInfo',
    clv: false,
    drg: false,
  },
  header: {
    showOpenBetsIcon: false,
  },
  // default values, that can be changed by user
  footer: {
    enabledCookieIsAccepted: false,
    orderList: {
      backToTop: {
        component: 'backToTop',
        index: 10,
      },
      footerImagesLinksElem: {
        component: 'footerImagesLinksElem',
        index: 20,
      },
      logo: {
        component: 'logoElem',
        index: 30,
      },
      languageSwitcher: {
        component: 'languageSwitcher',
        index: 40,
      },
      selectAppColor: {
        component: 'selectAppColor',
        index: 35,
      },
      viewSwitcher: {
        component: 'viewSwitcher',
        index: 50,
      },
      oddsFormatSwitcher: {
        component: 'oddsFormatSwitcher',
        index: 60,
      },
      checkBetStatusElem: {
        component: 'checkBetStatusElem',
        index: 70,
      },
      bookingBetId: {
        component: 'bookingBetId',
        index: 80,
      },
      footerHelpPage: {
        component: 'footerHelpPage',
        index: 90,
      },
      socialIcons: {
        component: 'socialIcons',
        index: 21,
      },
      liveChatElem: {
        component: 'liveChatElem',
        index: 110,
      },
      customFooterSolutionElem: {
        component: 'customFooterSolutionElem',
        index: 120,
      },
      footerTextsElem: {
        component: 'footerTextsElem',
        index: 130,
      },
      footerLogoElem: {
        component: 'footerLogoElem',
        index: 140,
      },
      iframeFromConfigELem: {
        component: 'iframeFromConfigELem',
        index: 150,
      },
      whatsappConfigsElem: {
        component: 'whatsappConfigsElem',
        index: 160,
      },
      eighteenPlusLogoElem: {
        component: 'eighteenPlusLogoElem',
        index: 170,
      },
      enabledCookieIsAccepted: {
        component: 'enabledCookieIsAccepted',
        index: 180,
      },
    },
  },
  env: {
    lang: 'eng',
    oddsFormat: 'decimal',
    selectedTheme: {},
  },
  geoLocationOddFormatSwitcher: {
    enabled: false,
    default: 'decimal',
    IE: 'fractional',
    GB: 'fractional',
    US: 'american',
    HK: 'hongkong',
    ID: 'indo',
    MY: 'malay',
  },
  disableCasinoAndSportsBonusesToShow: false,
  disableNavMenuFromLeftMenu: false,
  everCookie: {
    enabled: false,
    afecUrl: 'https://afec.betconstruct.com/topics/client-activity-v2',
    options: {
      history: false,
      baseurl: 'https://init-ec.betconstruct.com',
      asseturi: '/assets',
      swfFileName: '/ec.swf',
      xapFileName: '/ec.xap',
      jnlpFileName: '/ec.jnlp',
      pngCookieName: 'ec_png',
      pngPath: 'ec_png.php',
      etagCookieName: 'ec_etag',
      etagPath: 'ec_etag.php',
      cacheCookieName: 'ec_cache',
      cachePath: 'ec_cache.php',
      phpuri: '/',
      java: false,
      silverlight: false,
    },
  },
  restrictGeoLocation: {
    enabled: false,
    checkBeforeRegistration: false,
    checkBeforeLogin: false,
    checkBeforeBet: false,
  },
};
