export const APP_READY = 'APP_READY';
export const ADDITIONAL_CSS_LOADED = 'ADDITIONAL_CSS_LOADED';
export const PREFERENCES_SET = 'PREFERENCES_SET';
export const PREFERENCES_RESET = 'PREFERENCES_RESET';
export const SWARM_DATA_SINGLE_KEY_UPDATE = 'SWARM_DATA_SINGLE_KEY_UPDATE';
export const SWARM_LOADING_START = 'SWARM_LOADING_START';
export const SWARM_LOADING_DONE = 'SWARM_LOADING_DONE';
export const SWARM_DATA_RECEIVED = 'SWARM_DATA_RECEIVED';
export const SWARM_UPDATE_RECEIVED = 'SWARM_UPDATE_RECEIVED';
export const SWARM_CLEAR_DATA = 'SWARM_CLEAR_DATA';
export const SWARM_DELETE_NESTED_DATA = 'SWARM_DELETE_NESTED_DATA';
export const SWARM_DATA_BET_HISTORY_UNSETTLED_UPDATE_RECEIVED =
  'SWARM_DATA_BET_HISTORY_UNSETTLED_UPDATE_RECEIVED';
export const DESTROY_LOADED_BET_HISTORY = 'DESTROY_LOADED_BET_HISTORY';
export const SWARM_LOADING_FAILED = 'SWARM_LOADING_FAILED';
export const STORE_OPEN_BETS = 'STORE_OPEN_BETS';
export const STORE_BONUS_DETAILS = 'STORE_BONUS_DETAILS';
export const SWARM_CONFIG_DATA = 'SWARM_CONFIG_DATA';
export const CONNECTED = 'CONNECTED';
export const LOGIN_BUTTON_CLICKED = 'LOGIN_BUTTON_CLICKED';
export const LOGIN_START = 'LOGIN_START';
export const LOGGED_IN = 'LOGGED_IN'; // login state in UI
export const REALLY_LOGGED_IN = 'REALLY_LOGGED_IN'; // the real login state
export const LOGGED_OUT = 'LOGOUT';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SESSION_LOST = 'SESSION_LOST';
export const SESSION_ACTIVE = 'SESSION_ACTIVE';
export const UPDATE_COMPETITIONS = 'UPDATE_COMPETITIONS';
export const UPDATE_GAMES_BY_COMPETITION = 'UPDATE_GAMES_BY_COMPETITION';
export const USER_PROFILE_UPDATE_RECEIVED = 'USER_PROFILE_UPDATE_RECEIVED';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const HIDE_USER_BALANCE = 'HIDE_USER_BALANCE';
export const USER_NAME_BEFORE_BACKEND_CALL = 'USER_NAME_BEFORE_BACKEND_CALL';
export const STOP_LOADING_LOGIN = 'STOP_LOADING_LOGIN';
export const TWO_FACTOR_QR_PARAMS = 'TWO_FACTOR_QR_PARAMS';
export const CASINO_GAMES_AUTH_LOADING_DONE = 'CASINO_GAMES_AUTH_LOADING_DONE';
export const CASINO_GAMES_AUTH_LOADING_START = 'CASINO_GAMES_AUTH_LOADING_START';
export const CASINO_GAMES_AUTH_LOADING_FAILED = 'CASINO_GAMES_AUTH_LOADING_FAILED';
export const LOAD_CASINO_TOURNAMENT_LIST = 'LOAD_CASINO_TOURNAMENT_LIST';
export const CLEAR_CASINO_TOURNAMENT_LIST = 'CLEAR_CASINO_TOURNAMENT_LIST';
export const LOAD_CASINO_TOURNAMENT_ALL_LIST = 'LOAD_CASINO_TOURNAMENT_ALL_LIST';
export const LOAD_CASINO_TOURNAMENT_STARTED = 'LOAD_CASINO_TOURNAMENT_STARTED';
export const LOAD_CASINO_TOURNAMENT_FINISHED = 'LOAD_CASINO_TOURNAMENT_FINISHED';
export const LOAD_CASINO_TOURNAMENT_FAILED = 'LOAD_CASINO_TOURNAMENT_FAILED';
export const UPDATE_CASINO_TOURNAMENT_FILTERS = 'UPDATE_CASINO_TOURNAMENT_FILTERS';
export const LOAD_CASINO_TOURNAMENT_ALL_GAMES_FAILED = 'LOAD_CASINO_TOURNAMENT_ALL_GAMES_FAILED';
export const LOAD_CASINO_TOURNAMENT_ALL_GAMES_FINISHED =
  'LOAD_CASINO_TOURNAMENT_ALL_GAMES_FINISHED';
export const LOAD_CASINO_TOURNAMENT_ALL_GAMES_STARTED = 'LOAD_CASINO_TOURNAMENT_ALL_GAMES_STARTED';
export const LOAD_CASINO_TOURNAMENT_LIST_ITEM_INFO = 'LOAD_CASINO_TOURNAMENT_LIST_ITEM_INFO';
export const LOAD_CASINO_TOURNAMENT_LIST_ITEM_STATISTICS =
  'LOAD_CASINO_TOURNAMENT_LIST_ITEM_STATISTICS';
export const LOAD_CASINO_JACKPOT_ID = 'LOAD_CASINO_JACKPOT_ID';
export const LOAD_CASINO_JACKPOT_GAMES = 'LOAD_CASINO_JACKPOT_GAMES';
export const LOAD_CASINO_JACKPOT_BACKGROUNDS = 'LOAD_CASINO_JACKPOT_BACKGROUNDS';
export const LOAD_CASINO_JACKPOT_ITEMS = 'LOAD_CASINO_JACKPOT_ITEMS';
export const LOAD_CASINO_TOURNAMENT_LIST_ITEM_GAMES_STARTED =
  'LOAD_CASINO_TOURNAMENT_LIST_ITEM_GAMES_STARTED';
export const LOAD_CASINO_TOURNAMENT_LIST_ITEM_GAMES_SUCCESS =
  'LOAD_CASINO_TOURNAMENT_LIST_ITEM_GAMES_SUCCESS';
export const LOAD_CASINO_TOURNAMENT_LIST_ITEM_GAMES_FAILED =
  'LOAD_CASINO_TOURNAMENT_LIST_ITEM_GAMES_FAILED';
export const LOAD_CASINO_GAME_ID = 'LOAD_CASINO_GAME_ID';
export const GAMES_INFO_LOADING_STARTED = 'GAMES_INFO_LOADING_STARTED';
export const GAMES_INFO_LOADING_SUCCESS = 'GAMES_INFO_LOADING_SUCCESS';
export const GAMES_INFO_LOADING_FAILED = 'GAMES_INFO_LOADING_FAILED';
// persistent UI
export const PREMATCH_TIME_FILTER = 'PREMATCH_TIME_FILTER';
export const LIVE_VIDEO_FILTER = 'LIVE_VIDEO_FILTER';
export const PREMATCH_WIDGET_TIME_FILTER = 'PREMATCH_WIDGET_TIME_FILTER';
export const UI_EXPAND_ELEMENT = 'UI_EXPAND_ELEMENT';
export const UI_COLLAPSE_ELEMENT = 'UI_COLLAPSE_ELEMENT';
export const GAME_SELECTED = 'GAME_SELECTED';
// non-persistent UI
export const UI_DISABLE_BLUR = 'UI_DISABLE_BLUR';
export const UI_ENABLE_BLUR = 'UI_ENABLE_BLUR';
export const UI_OPEN = 'UI_OPEN';
export const UI_CLOSE = 'UI_CLOSE';
export const UI_LOADING = 'UI_LOADING';
export const UI_LOADING_DONE = 'UI_LOADING_DONE';
export const UI_LOADING_FAILED = 'UI_LOADING_FAILED';
export const UI_LOADING_RESET = 'UI_LOADING_RESET';
export const UI_LAST_ROUTE_TYPE = 'UI_LAST_ROUTE_TYPE';
export const UI_PREVIOUS_PATH = 'UI_PREVIOUS_PATH';
export const UI_CURRENT_ROUTE_TYPE = 'UI_CURRENT_ROUTE_TYPE';
export const SCROLL_TO_SPORT_ALIAS = 'SCROLL_TO_SPORT_ALIAS';
export const SCROLLED_TO_SPORT_ALIAS = 'SCROLLED_TO_SPORT_ALIAS';
export const GDPR_PASSED_DONE = 'GDPR_PASSED_DONE';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const CONFIRMATION_DIALOG_ANSWER = 'CONFIRMATION_DIALOG_ANSWER';
export const CONFIRMATION_DIALOG_RESET = 'CONFIRMATION_DIALOG_RESET';
export const OPEN_EXTERNAL_POPUP = 'OPEN_EXTERNAL_POPUP';
export const HIDE_ANDROID_APP_DOWNLOAD_POPUP = 'HIDE_ANDROID_APP_DOWNLOAD_POPUP';
export const HIDE_CASINO_ANDROID_APP_DOWNLOAD_POPUP = 'HIDE_CASINO_ANDROID_APP_DOWNLOAD_POPUP';
export const FAVORITE_ADD = 'FAVORITE_ADD';
export const FAVORITE_REMOVE = 'FAVORITE_REMOVE';
export const FAVORITES_CLEAR = 'FAVORITES_CLEAR';
export const GAMES_FAVORITES_CLEAR = 'GAMES_FAVORITES_CLEAR';
export const COMPETITIONS_FAVORITES_CLEAR = 'COMPETITIONS_FAVORITES_CLEAR';
export const CASINO_FAVORITES_CLEAR = 'CASINO_FAVORITES_CLEAR';
export const MARKET_FAVORITES_CLEAR = 'MARKET_FAVORITES_CLEAR';
export const MAKE_QUICK_BET = 'MAKE_QUICK_BET';
export const QUCIK_BET_MESSAGE = 'QUCIK_BET_MESSAGE';
export const BETSLIP_TOGGLE_EVENT = 'BETSLIP_TOGGLE_EVENT';
export const BETSLIP_ADD = 'BETSLIP_ADD';
export const BETSLIP_REMOVE = 'BETSLIP_REMOVE';
export const BETSLIP_CLEAR = 'BETSLIP_CLEAR';
export const BETSLIP_SET_TYPE = 'BETSLIP_SET_TYPE';
export const BETSLIP_ACCEPT_CHANGES = 'BETSLIP_ACCEPT_CHANGES';
export const BETSLIP_TOGGLE_QUICKBET = 'BETSLIP_TOGGLE_QUICKBET';
export const BETSLIP_TOGGLE_BOOKINGBET = 'BETSLIP_TOGGLE_BOOKINGBET';
export const BETSLIP_TOGGLE_SUGGESTINGBET = 'BETSLIP_TOGGLE_SUGGESTINGBET';
export const BETSLIP_TOGGLE_REPEAT_BET = 'BETSLIP_TOGGLE_REPEAT_BET';
export const EVENT_ADD_SUGGESTINGBET = 'EVENT_ADD_SUGGESTINGBET';
export const BETSLIP_SWITCHER_SUGGESTINGBET = 'BETSLIP_SWITCHER_SUGGESTINGBET';
export const BETSLIP_TOGGLE_SUPERBET = 'BETSLIP_TOGGLE_SUPERBET';
export const BETSLIP_TOGGLE_FREEBET = 'BETSLIP_TOGGLE_FREEBET';
export const BETSLIP_BET_ACCEPTED = 'BETSLIP_BET_ACCEPTED';
export const BETSLIP_BET_FAILED = 'BETSLIP_BET_FAILED';
export const BETSLIP_BET_PROCESSING = 'BETSLIP_BET_PROCESSING';
export const BETSLIP_RESET_STATUS = 'BETSLIP_RESET_STATUS';
export const SET_BET_EVENT_COUNTER_OFFER_ODD = 'SET_BET_EVENT_COUNTER_OFFER_ODD';
export const BETSLIP_SET_STAKE = 'BETSLIP_SET_STAKE';
export const SET_UK_BETSLIP_OPTION_STAKE = 'SET_UK_BETSLIP_OPTION_STAKE';
export const UNSET_UK_BETSLIP_OPTION = 'UNSET_UK_BETSLIP_OPTION';
export const UNSET_UK_BETSLIP_OPTION_STAKE = 'UNSET_UK_BETSLIP_OPTION_STAKE';
export const UPDATE_BETSLIP_EVENTS = 'UPDATE_BETSLIP_EVENTS';
export const TOGGLE_UK_BETSLIP_MULTIPLE_OPTIONS = 'TOGGLE_UK_BETSLIP_MULTIPLE_OPTIONS';
export const BETSLIP_SET_UNIT_STAKE = 'BETSLIP_SET_UNIT_STAKE';
export const BETSLIP_SET_ALL_EVENTS_STAKE = 'BETSLIP_SET_ALL_EVENTS_STAKE';
export const BETSLIP_SET_EACH_WAY_MODE = 'BETSLIP_SET_EACH_WAY_MODE';
export const BETSLIP_SET_SYSTEM_OPT = 'BETSLIP_SET_SYSTEM_OPT';
export const BETSLIP_SET_LAST_BET = 'BETSLIP_SET_LAST_BET';
export const BETSLIP_SET_ACCEPT_OPT = 'BETSLIP_SET_ACCEPT_OPT';
export const BETSLIP_SET_BET_EACH_WAY = 'BETSLIP_SET_BET_EACH_WAY';
export const BETSLIP_TOGGLE_FREEBET_LOADING_STATE = 'BETSLIP_TOGGLE_FREEBET_LOADING_STATE';
export const BETSLIP_TOGGLE_COUNTER_OFFER = 'BETSLIP_TOGGLE_COUNTER_OFFER';
export const BETSLIP_TOGGLE_PROFIT_BOOST = 'BETSLIP_TOGGLE_PROFIT_BOOST';
export const BETSLIP_SET_INCLUDE_IN_SYSTEM_CALC = 'BETSLIP_SET_INCLUDE_IN_SYSTEM_CALC';
export const BETSLIP_QUICK_BET_NOTIFY = 'BETSLIP_QUICK_BET_NOTIFY';
export const BETSLIP_QUICK_BET_NOTIFY_DISMISS = 'BETSLIP_QUICK_BET_NOTIFY_DISMISS';
export const BETSLIP_SET_FREE_BETS = 'BETSLIP_SET_FREE_BETS';
export const BETSLIP_SELECT_FREE_BET = 'BETSLIP_SELECT_FREE_BET';
export const BETSLIP_UNSELECT_FREE_BET = 'BETSLIP_UNSELECT_FREE_BET';
export const BETSLIP_EDIT_ODD_TYPE = 'BETSLIP_EDIT_ODD_TYPE';
export const TOGGLE_SYSTEM_BET_CALCULATOR = 'TOGGLE_SYSTEM_BET_CALCULATOR';
export const SHOW_MINI_BETSLIP = 'SHOW_MINI_BETSLIP';
export const EDIT_BET_TOGGLE = 'EDIT_BET_TOGGLE';
export const EDIT_BET_ADD_SELECTIONS_TOGGLE = 'EDIT_BET_ADD_SELECTIONS_TOGGLE';
export const BETSLIP_ADD_EDIT_BET = 'BETSLIP_ADD_EDIT_BET';
export const BETSLIP_REMOVE_EDIT_BET = 'BETSLIP_REMOVE_EDIT_BET';
export const TO_EDIT_BET_BETSLIP = 'TO_EDIT_BET_BETSLIP';
export const EDIT_BET_RESET_EVENTS = 'EDIT_BET_RESET_EVENTS';
export const EDIT_BET_EVENTS_SAVE_CHANGES = 'EDIT_BET_EVENTS_SAVE_CHANGES';
export const SAVE_EDIT_BET_CHANGES = 'SAVE_EDIT_BET_CHANGES';
export const BETSLIP_SET_EDIT_BET_ID = 'BETSLIP_SET_EDIT_BET_ID';
export const BETSLIP_SET_EDIT_BET_STAKE = 'BETSLIP_SET_EDIT_BET_STAKE';
export const DISABLE_AUTHENTICATION_BUTTON = 'DISABLE_AUTHENTICATION_BUTTON';
export const CMS_LOAD_START = 'CMS_LOAD_START';
export const CMS_LOAD_DONE = 'CMS_LOAD_DONE';
export const CMS_DATA_RECEIVED = 'CMS_DATA_RECEIVED';
export const CMS_CLEAR_DATA = 'CMS_CLEAR_DATA';
// registration captcha
export const CAPTCH_LOAD_SUCCESS = 'CAPTCH_LOAD_SUCCESS';
export const CAPTCH_LOAD_FAILURE = 'CAPTCH_LOAD_FAILURE';
export const UPDATE_CAPTCHA_KEY = 'UPDATE_CAPTCHA_KEY';
export const REMOVE_CAPTCHA_INFO = 'REMOVE_CAPTCHA_INFO';
export const UPDATE_MESSAGES_UNREAD_COUNT = 'UPDATE_MESSAGES_UNREAD_COUNT';
export const SET_REGISTRATION_ACTIVE_STEP = 'SET_REGISTRATION_ACTIVE_STEP';
export const RESET_REGISTRATION_DATA = 'RESET_REGISTRATION_DATA';
export const SET_INITIAL_VALUES_FOR_NEXT_STEP = 'SET_INITIAL_VALUES_FOR_NEXT_STEP';
export const SET_REGISTRATION_ACTIVE_STEP_WITH_EMPTY_DATA =
  'SET_REGISTRATION_ACTIVE_STEP_WITH_EMPTY_DATA';
export const LOAD_EXTERNAL_SCRIPT = 'LOAD_EXTERNAL_SCRIPT';
export const BETSHOP_LOADING_DONE = 'BETSHOP_LOADING_DONE';
export const BETSHOP_LOADING_START = 'BETSHOP_LOADING_START';
export const BETSHOP_LOADING_FAILURE = 'BETSHOP_LOADING_FAILURE';
export const FILTERS_LOADING_DONE = 'FILTERS_LOADING_DONE';
export const FILTERS_LOADING_START = 'FILTERS_LOADING_START';
export const FILTERS_LOADING_FAILURE = 'FILTERS_LOADING_FAILURE';
export const OPEN_PAYMENT_METHOD_FORM = 'OPEN_PAYMENT_METHOD_FORM';
export const CLOSE_PAYMENT_METHOD_FORM = 'CLOSE_PAYMENT_METHOD_FORM';
export const FILTER_PAYMENTS_FOR_USER_CURRENCY = 'FILTER_PAYMENTS_FOR_USER_CURRENCY';
export const SET_EXTERNAL_FORM_DATA = 'SET_EXTERNAL_FORM_DATA';
export const UNSET_EXTERNAL_FORM_DATA = 'UNSET_EXTERNAL_FORM_DATA';
export const SET_METHOD_IFRAME_DATA = 'SET_METHOD_IFRAME_DATA';
export const UNSET_METHOD_IFRAME_DATA = 'UNSET_METHOD_IFRAME_DATA';
export const SET_METHOD_CONFIRM_ACTION = 'SET_METHOD_CONFIRM_ACTION';
export const UNSET_METHOD_CONFIRM_ACTION = 'UNSET_METHOD_CONFIRM_ACTION';
export const SET_DEPOSIT_NEXT_STEP = 'SET_DEPOSIT_NEXT_STEP';
export const UNSET_DEPOSIT_NEXT_STEP = 'UNSET_DEPOSIT_NEXT_STEP';
export const LOAD_CLIENT_BANK_INFO = 'LOAD_CLIENT_BANK_INFO';
export const GET_BUDDY_TO_BUDDY_FRIEND_LIST = 'GET_BUDDY_TO_BUDDY_FRIEND_LIST';
export const SET_PAYMENT_FIELD_TYPES = 'SET_PAYMENT_FIELD_TYPES';
export const SET_DEPOSIT_PERMISSIONS_DATA = 'SET_DEPOSIT_PERMISSIONS_DATA';
export const REMOVE_USER_ERROR_MESSAGES = 'REMOVE_USER_ERROR_MESSAGES';
// casino
export const PROVIDERS_AND_CATEGORIES_LOADING_STARTED = 'PROVIDERS_AND_CATEGORIES_LOADING_STARTED';
export const PROVIDERS_AND_CATEGORIES_LOADING_FAILED = 'PROVIDERS_AND_CATEGORIES_LOADING_FAILED';
export const PROVIDERS_AND_CATEGORIES_LOADING_SUCCESS = 'PROVIDERS_AND_CATEGORIES_LOADING_SUCCESS';
export const CASINO_GAMES_LOADING_STARED = 'CASINO_GAMES_LOADING_STARED';
export const CASINO_GAMES_LOADING_FAILED = 'CASINO_GAMES_LOADING_FAILED';
export const CASINO_GAMES_LOADING_SUCCESS = 'CASINO_GAMES_LOADING_SUCCESS';
export const LIVE_CASINO_GAMES_LOADING_STARED = 'LIVE_CASINO_GAMES_LOADING_STARED';
export const LIVE_CASINO_GAMES_LOADING_FAILED = 'LIVE_CASINO_GAMES_LOADING_FAILED';
export const LIVE_CASINO_GAMES_LOADING_SUCCESS = 'LIVE_CASINO_GAMES_LOADING_SUCCESS';
export const CASINO_FILTER_CHANGE = 'CASINO_FILTER_CHANGE';
export const GET_CATEGORIES_AND_PROVIDERS = 'GET_CATEGORIES_AND_PROVIDERS';
export const CASINO_GAMES_MOST_POPULAR_GAME_LOADING_STARED =
  'CASINO_GAMES_MOST_POPULAR_GAME_LOADING_STARED';
export const CASINO_GAMES_MOST_POPULAR_GAME_LOADING_FAILED =
  'CASINO_GAMES_MOST_POPULAR_GAME_LOADING_FAILED';
export const CASINO_GAMES_MOST_POPULAR_GAME_LOADING_SUCCESS =
  'CASINO_GAMES_MOST_POPULAR_GAME_LOADING_SUCCESS';
export const SELECT_CASINO_GAMES_CATEGORY = 'SELECT_CASINO_GAMES_CATEGORY';
export const SELECT_CASINO_GAMES_PROVIDER = 'SELECT_CASINO_GAMES_PROVIDER';
export const SELECT_LIVE_CASINO_GAMES_PROVIDER = 'SELECT_LIVE_CASINO_GAMES_PROVIDER';
// virtual sport
export const SET_GAME_INFO_FOR_VIDEO = 'SET_GAME_INFO_FOR_VIDEO';
// messages
export const DELETE_MESSAGE_BY_ID = 'DELETE_MESSAGE_BY_ID';
// bet history filters
export const SET_BET_HISTORY_FILTERS = 'SET_BET_HISTORY_FILTERS';
export const RESET_BET_HISTORY_FILTERS = 'RESET_BET_HISTORY_FILTERS';
export const SET_CASH_OUT_VALUE = 'SET_CASH_OUT_VALUE';
export const SET_AUTO_CASH_OUT_VALUE = 'SET_AUTO_CASH_OUT_VALUE';
// hash params
export const STORE_HASH_PARAMS = 'STORE_HASH_PARAMS';
export const RESET_HASH_PARAMS = 'RESET_HASH_PARAMS';
export const UPDATE_HASH_PARAMS = 'UPDATE_HASH_PARAMS';
// virtual keyboard
export const TOGGLE_VIRTUAL_KEYBOARD = 'TOGGLE_VIRTUAL_KEYBOARD';
// reducerMarketFilter
export const SET_MARKET_GROUP_FILTER = 'SET_MARKET_GROUP_FILTER';
//generic postfixes
export const START_POSTFIX = '_START';
export const DONE_POSTFIX = '_DONE';
export const FAIL_POSTFIX = '_FAIL';
// euro payment specific actions
export const CREATE_MESSAGES_FOR_PAYMENT_METHOD_FAIL = 'CREATE_MESSAGES_FOR_PAYMENT_METHOD_FAIL';
export const CREATE_MESSAGES_FOR_PAYMENT_METHOD_DONE = 'CREATE_MESSAGES_FOR_PAYMENT_METHOD_DONE';
export const CREATE_MESSAGES_FOR_PAYMENT_METHOD_START = 'CREATE_MESSAGES_FOR_PAYMENT_METHOD_START';
export const ACTIVE_MESSAGES_FOR_PAYMENT_METHOD_LOADING_FAIL =
  'ACTIVE_MESSAGES_FOR_PAYMENT_METHOD_LOADING_FAIL';
export const ACTIVE_MESSAGES_FOR_PAYMENT_METHOD_LOADING_DONE =
  'ACTIVE_MESSAGES_FOR_PAYMENT_METHOD_LOADING_DONE';
export const ACTIVE_MESSAGES_FOR_PAYMENT_METHOD_LOADING_START =
  'ACTIVE_MESSAGES_FOR_PAYMENT_METHOD_LOADING_START';
//withdrawals status specific actions
export const WITHDRAWALS_LOADING_START = 'WITHDRAWALS_LOADING_START';
export const WITHDRAWALS_LOADING_DONE = 'WITHDRAWALS_LOADING_DONE';
export const WITHDRAWALS_LOADING_FAILURE = 'WITHDRAWALS_LOADING_FAILURE';
//free quiz specific actions
export const FREE_QUIZ_SET_DATE_FILTER = 'FREE_QUIZ_SET_DATE_FILTER';
export const FREE_QUIZ_LOADING_FAILED = 'FREE_QUIZ_LOADING_FAILED';
export const FREE_QUIZ_LOADING_DONE = 'FREE_QUIZ_LOADING_DONE';
export const FREE_QUIZ_LOADING_START = 'FREE_QUIZ_LOADING_START';
export const FREE_QUIZ_TOGGLE_SELECTED_EVENT = 'FREE_QUIZ_TOGGLE_SELECTED_EVENT';
export const FREE_QUIZ_SET_SELECTED_EVENTS = 'FREE_QUIZ_SET_SELECTED_EVENTS';
// restore forms initial value actions
export const COLLECT_INITIAL_DATA = 'COLLECT_INITIAL_DATA';
export const DELETE_STORED_FORM_DATA = 'DELETE_STORED_FORM_DATA';
export const BULK_UPDATE_DATA = 'BULK_UPDATE_DATA';
export const ZIMPLER_SEEN = 'ZIMPLER_SEEN';
// asian view actions
export const UPDATE_ASIAN_VIEW_PAGINATION = 'UPDATE_ASIAN_VIEW_PAGINATION';
export const RESET_ASIAN_PAGINATION = 'RESET_ASIAN_PAGINATION';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const ASIAN_TIME_FILTERS_LOADING_STARTED = 'ASIAN_TIME_FILTERS_LOADING_STARTED';
export const ASIAN_TIME_FILTERS_LOADING_FAILED = 'ASIAN_TIME_FILTERS_LOADING_FAILED';
export const TOGGLE_ASIAN_FILTERS = 'TOGGLE_ASIAN_FILTERS';
export const UPDATE_ASIAN_TIME_FILTERS_COUNTS = 'UPDATE_ASIAN_TIME_FILTERS_COUNTS';
export const UPDATE_MORE_ASIAN_GAME_MARKETS = 'UPDATE_MORE_ASIAN_GAME_MARKETS';
export const UPDATE_ASIAN_COMPETITIONS_FILTERS = 'UPDATE_ASIAN_COMPETITIONS_FILTERS';
export const APPLY_ASIAN_COMPETITIONS_FILTERS = 'APPLY_ASIAN_COMPETITIONS_FILTERS';
export const APPLY_ASIAN_TIME_FILTERS = 'APPLY_ASIAN_TIME_FILTERS';
export const APPLY_ASIAN_ORDER_FILTERS = 'APPLY_ASIAN_ORDER_FILTERS';
export const SET_ACCEPTANCE_VERSION = 'SET_ACCEPTANCE_VERSION';
export const SET_APP_THEME = 'SET_APP_THEME';
// boosted odds actions
export const BOOSTED_ODDS_GAMES = 'BOOSTED_ODDS_GAMES';
// session control actions
export const SET_SESSION_DURATION = 'SET_SESSION_DURATION';
export const SESSION_OPENED = 'SESSION_OPENED';

// upload actions

export const UPLOAD_FILE = 'UPLOAD_FILE';

export const GET_CLIENT_PRE_CALCULATION = 'GET_CLIENT_PRE_CALCULATION';

// promotions
export const GET_PLAYER_OPT_INS = 'GET_PLAYER_OPT_INS';
export const CLIENT_OPT_IN = 'CLIENT_OPT_IN';
export const CLIENT_OPT_OUT = 'CLIENT_OPT_OUT';
